const LocalWhen = {
  init: () => {
    document.querySelectorAll("[data-localwhen]").forEach(elm => {
      let d = new Date(parseFloat(elm.dataset.localwhen) * 1000);
      elm.innerText = d.toLocaleString();
    });
  },
};

export { LocalWhen };
