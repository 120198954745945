const OffCanvas = {
  init: () => {
    const html = document.querySelector("html");
    const header = document.querySelector("[data-site-header]");
    const nav = header.querySelector("[data-nav]");
    const navOpen = header.querySelector("[data-nav-open]");
    const mediaQueryList = window.matchMedia("(max-width: 64em)");

    if (!nav) return;

    function toggleNav(e) {
      const ariaHidden = nav.getAttribute("aria-hidden");
      header.classList.toggle("site-header--open");
      if (ariaHidden === "true") {
        nav.setAttribute("aria-hidden", "false");
      } else {
        nav.setAttribute("aria-hidden", "true");
      }
      html.classList.toggle("no-scroll");
      e.preventDefault();
    }

    function handle_size_change(e) {
      if (e.matches) {
        nav.setAttribute("aria-hidden", "true");
        navOpen.addEventListener("click", toggleNav, false);
      } else {
        nav.setAttribute("aria-hidden", "false");
        header.classList.remove("site-header--open");
        html.classList.remove("no-scroll");
      }
    }

    // Register event listener
    // Includes fallback for Safari <14
    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener("change", handle_size_change, false);
    } else {
      mediaQueryList.addListener(handle_size_change);
    }

    // Initial check
    handle_size_change(mediaQueryList);
  },
};

export { OffCanvas };
