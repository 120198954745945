const ExclusiveCheckboxes = {
  init: () => {
    document.querySelectorAll(".form__fieldset.exclusive").forEach(fieldset => {
      fieldset.querySelectorAll("input[type=checkbox]").forEach(checkbox => {
        checkbox.addEventListener("change", () => {
          if (checkbox.checked) {
            fieldset.parentElement.querySelectorAll(`.form__fieldset.exclusive:not(.categoryindex-${checkbox.dataset.categoryindex}) input[type=checkbox]`).forEach(otherCheckbox => {
              otherCheckbox.checked = false;
            })
          }
        });
      });
    })
  }
};

export { ExclusiveCheckboxes };
